import Dashboard from "./dashboard";
import Users from "./users";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/users",
    component: Users,
  },
];

export default routes;
