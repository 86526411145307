import Bots from "./bots";
import Dashboard from "./dashboard";
import Distresses from "./distresses";
import Hobbies from "./hobbies";
import Relivers from "./relivers";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/hobbies",
    component: Hobbies,
  },
  {
    path: "/distresses",
    component: Distresses,
  },
  {
    path: "/relivers",
    component: Relivers,
  },
  {
    path: "/chat",
    component: Bots,
  },
];

export default routes;
