const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "Hobbies",
    icon: "gamepad",
    path: "/hobbies",
  },
  {
    name: "Distresses",
    icon: "diagnoses",
    path: "/distresses",
  },
  {
    name: "Relivers",
    icon: "hand-holding-heart",
    path: "/relivers",
  },
  {
    name: "Chatbot",
    icon: "robot",
    path: "/chat",
  },
];

export default sidebar;
