import React from "react";
import Phone from "../../../assets/landing/mobile.png";
import { MDBRow, MDBCol, MDBIcon, MDBAnimation } from "mdbreact";

export default function Description() {
  return (
    <section className="section my-5wow fadeIn" data-wow-delay="0.3s">
      <MDBAnimation type="fadeInUp" reveal>
        <h1 className="text-center my-5 h1">Why is it so great?</h1>
      </MDBAnimation>
      <MDBAnimation type="fadeInUp" reveal>
        <p className="text-center mb-5 w-responsive mx-auto ">
          Welcome to Kaagapp-ai, inspired by the Filipino term "kaagapay,"
          meaning "a companion or partner by your side." Kaagapp-ai is designed
          to be more than just a tool; it’s a trusted companion for navigating
          complex challenges with ease and innovation. Join our community today
          and discover why Kaagapp-ai has quickly become a beloved platform for
          countless users!
        </p>
      </MDBAnimation>
      <MDBRow>
        <MDBCol md="4">
          <MDBRow className="mb-2">
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInLeftBig" duration="1.25s">
                <MDBIcon size="2x" className="indigo-text" icon="shield-alt" />
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="10">
              <MDBAnimation reveal type="fadeInLeftBig">
                <h5 className="font-weight-bold my-4">Secure</h5>
                <p>
                  Your data and privacy are our top priority. We leverage
                  cutting-edge technology to ensure a secure experience every
                  time.
                </p>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-2">
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInLeftBig" duration="1.25s">
                <MDBIcon size="2x" className="blue-text" icon="flask" />
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="10">
              <MDBAnimation reveal type="fadeInLeftBig">
                <h5 className="font-weight-bold my-4">Experimental</h5>
                <p>
                  Kaagapp-ai is designed for the curious mind, providing tools
                  and resources for creative experimentation and exploration in
                  AI.
                </p>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-2">
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInLeftBig" duration="1.25s">
                <MDBIcon
                  size="2x"
                  className="cyan-text"
                  icon="glass-martini-alt"
                />
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="10">
              <MDBAnimation reveal type="fadeInLeftBig">
                <h5 className="font-weight-bold my-4">Relaxing</h5>
                <p>
                  Let Kaagapp-ai simplify your workload, freeing up time for
                  what matters most to you.
                </p>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol md="4 mb-2 text-center text-md-left flex-center">
          <MDBAnimation reveal type="fadeInUp">
            <img src={Phone} alt="" className="z-depth-0 w-100" />
          </MDBAnimation>
        </MDBCol>

        <MDBCol md="4">
          <MDBRow className="mb-2">
            <MDBCol size="10" className="text-right">
              <MDBAnimation reveal type="fadeInRightBig">
                <h5 className="font-weight-bold my-4">Beloved</h5>
                <p>
                  Kaagapp-ai’s growing community attests to its reliability and
                  friendly user experience.
                </p>
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInRightBig" duration="1.25s">
                <MDBIcon size="2x" icon="heart" className="deep-purple-text" />
              </MDBAnimation>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-2">
            <MDBCol size="10" className="text-right">
              <MDBAnimation reveal type="fadeInRightBig">
                <h5 className="font-weight-bold my-4">Rapid</h5>
                <p>
                  Fast, efficient, and ready to support you whenever you need
                  it.
                </p>
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInRightBig" duration="1.25s">
                <MDBIcon size="2x" icon="bolt" className="text-warning" />
              </MDBAnimation>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-2">
            <MDBCol size="10" className="text-right">
              <MDBAnimation reveal type="fadeInRightBig">
                <h5 className="font-weight-bold my-4">Magical</h5>
                <p>
                  Experience the magic of AI as Kaagapp-ai brings your ideas to
                  life, turning complex tasks into effortless solutions.
                </p>
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInRightBig" duration="1.25s">
                <MDBIcon size="2x" icon="magic" className="pink-text" />
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </section>
  );
}
