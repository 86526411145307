const Steps = [
  {
    intro: "Welcome to Kaagapp-AI we are happy to serve you!",
  },
  {
    element: "#total-hobbies",
    intro: "This is the total of your hobbies.",
  },
  {
    element: "#total-distresses",
    intro: "This is the total of your distresses.",
  },
  {
    element: "#total-relivers",
    intro: "This is the total of your relivers.",
  },
  {
    element: "#chat-bot",
    intro:
      "Here is your favorites chats that help you get motivated and advices that helps you.",
  },
  {
    element: "#Dashboard-link",
    intro: "Click this to go to dashboard.",
  },
  {
    element: "#Hobbies-link",
    intro: "Click this to go to manage your hobbies.",
  },
  {
    element: "#Distresses-link",
    intro: "Click this to go to manage your distresses.",
  },
  {
    element: "#Relivers-link",
    intro: "Click this to go to manage your relivers.",
  },
  {
    element: "#Chatbot-link",
    intro:
      "To access our chat bot please add 3 or more hobbies, distress, and relivers!",
  },
];
export default Steps;
