const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "Users",
    icon: "user",
    path: "/users",
  },
];

export default sidebar;
