import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBIcon,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { RESET, USERS } from "../../../../services/redux/slices/statistics";
import Bots from "../bots";
import Introjs from "intro.js";
import Steps from "../../../../services/utilities/tour";

export default function Dashboard() {
  const { token, auth } = useSelector(({ auth }) => auth),
    { collections } = useSelector(({ statistics }) => statistics),
    [stat, setStat] = useState({}),
    dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      var key = {
        user: `{"$eq": "${auth._id}"}`,
      };
      dispatch(
        USERS({
          token,
          key,
        })
      );
    }

    return () => {
      dispatch(RESET());
    };
  }, [token, auth, dispatch]);

  useEffect(() => {
    if (collections) {
      setStat(collections);
    }
  }, [collections]);

  return (
    <MDBContainer fluid id="v6" className="mb-5">
      <section className="mb-4">
        <MDBRow>
          <MDBCol md="4" className="mb-4 mb-r" id="total-hobbies">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <MDBBtn
                    tag="a"
                    floating
                    size="lg"
                    color="success"
                    className="ml-4"
                    style={{ padding: 0 }}>
                    <MDBIcon icon="gamepad" size="2x" />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h5 className="ml-4 mt-4 mb-2 font-weight-bold text-success">
                    {stat.HobbiesCount}
                  </h5>
                  <p className="font-small grey-text">Total Hobbies</p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>

          <MDBCol md="4" className="mb-4 mb-r" id="total-distresses">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" col="5" className="text-left pl-4">
                  <MDBBtn
                    tag="a"
                    floating
                    size="lg"
                    color="danger"
                    className="ml-4"
                    style={{ padding: 0 }}>
                    <MDBIcon icon="diagnoses" size="2x" />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h5 className="ml-4 mt-4 mb-2 font-weight-bold text-danger">
                    {stat.DistressesCount}
                  </h5>
                  <p className="font-small grey-text">Total Distresses</p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4" className="mb-4 mb-r" id="total-relivers">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" col="5" className="text-left pl-4">
                  <MDBBtn
                    tag="a"
                    floating
                    size="lg"
                    color="info"
                    className="ml-4"
                    style={{ padding: 0 }}>
                    <MDBIcon icon="hand-holding-heart" size="2x" />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h5 className="ml-4 mt-4 mb-2 font-weight-bold text-info">
                    {stat.ReliversCount}
                  </h5>
                  <p className="font-small grey-text">Total of Relivers</p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
      <section className="mb-5">
        <Bots FavChats={stat.FavChats} />
      </section>
      {/* <section className="mb-5">
        <MDBCard cascade narrow>
          <MDBRow>
            <MDBCol md="12" className="mr-0">
              <MDBCardBody cascade className="pb-3">
                <MDBRow className="pt-3 card-body">
                  <MDBCol md="12">
                    <h5>
                      <MDBBadge className="big-badge light-blue lighten-1">
                        Filter
                      </MDBBadge>
                    </h5>
                    <br />
                    <div className="mb-1">
                      <MDBRow>
                        <MDBCol size="6">
                          <small className="grey-text">from:</small>
                          <MDBDatePicker
                            getValue={e => {
                              setDate(new Date(e).toISOString().split("T")[0]);
                            }}
                            className="my-0 d-inline ml-3"
                          />
                        </MDBCol>
                        <MDBCol size="6">
                          <small className="grey-text">to:</small>
                          <MDBDatePicker
                            getValue={e => {
                              setTo(new Date(e).toISOString().split("T")[0]);
                            }}
                            className="my-0 d-inline ml-3"
                          />
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
          <MDBCol md="12">
            <MDBView cascade className="gradient-card-header white ">
              <Bar
                className="overflow-scroll"
                data={stat}
                options={barChartOptions}
                height={150}
              />
            </MDBView>
          </MDBCol>
        </MDBCard>
      </section> */}
    </MDBContainer>
  );
}
