import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DESTROY,
  FIND,
  RESET,
  UPDATE,
} from "../../../../services/redux/slices/distresses";
import { useToasts } from "react-toast-notifications";
import Modal from "./modal";
import DataTable from "../../../../components/dataTable";
import { globalSearch } from "../../../../services/utilities";
import Swal from "sweetalert2";
import Bots from "../bots";
import { MDBBtn, MDBContainer } from "mdbreact";

export default function Distresses() {
  const [distresses, setDistresses] = useState([]),
    isActive = true,
    [selected, setSelected] = useState({}),
    [showModal, setShowModal] = useState(false),
    [willCreate, setWillCreate] = useState(true),
    [active, setActive] = useState("Stress"),
    { token, auth } = useSelector(({ auth }) => auth),
    { collections, message, isSuccess, isLoading, total } = useSelector(
      ({ distresses }) => distresses
    ),
    { addToast } = useToasts(),
    categories = ["Stress", "Anxiety", "Loneliness"],
    dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(
        FIND({
          token,
          key: {
            user: `{"$eq": "${auth._id}"}`,
          },
        })
      );
    }

    return () => dispatch(RESET());
  }, [token, auth, dispatch]);

  useEffect(() => {
    setDistresses(
      collections.filter(
        e =>
          e.deleted === !isActive &&
          e.category.toUpperCase() === active.toUpperCase()
      )
    );
  }, [isActive, collections, active]);

  const toggleModal = () => setShowModal(!showModal);

  const handleUpdate = selected => {
    setSelected(selected);
    if (willCreate) {
      setWillCreate(false);
    }
    setShowModal(true);
  };

  const handleCreate = () => {
    if (!willCreate) {
      setWillCreate(true);
    }
    setSelected({});
    setShowModal(true);
  };

  const handleRestore = selected =>
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to restore ${selected.length} ${
        selected.length > 1 ? "distresses" : "distress"
      }.`,
      icon: "question",
      confirmButtonText: "Proceed",
    }).then(res => {
      if (res.isConfirmed) {
        const toRestore = selected.map(item => ({
          balance: item.balance,
          _id: item._id,
          deleted: false,
        }));

        dispatch(
          UPDATE({
            data: toRestore.length > 1 ? toRestore : toRestore[0],
            token,
          })
        );
      }
    });

  const handleDestroy = async selected => {
    const { value: reason } = await Swal.fire({
      title: "Are you sure?",
      text: `You are about to permanently delete ${selected.length} Budget(s).`,
      icon: "warning",
      confirmButtonText: "Proceed",
      cancelButtonColor: "#d33",
      showCancelButton: true,
    });

    if (reason) {
      const toDelete = selected.map(item => ({
        _id: item._id,
        balance: item.balance,
        deleted: true,
      }));

      dispatch(
        DESTROY({
          data: toDelete.length > 1 ? toDelete : toDelete[0],
          token,
        })
      );
    }
  };

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);

  const handleSearch = async (willSearch, key) => {
    if (willSearch) {
      setDistresses(globalSearch(collections, key));
    } else {
      setDistresses(collections);
    }
  };

  return (
    <>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        {categories.map((category, index) => (
          <MDBBtn
            key={index}
            color={
              active.toUpperCase() === category.toUpperCase()
                ? "success"
                : "info"
            }
            onClick={() => setActive(category)}>
            {category}
          </MDBBtn>
        ))}
      </MDBContainer>
      <DataTable
        isLoading={isLoading}
        title={`${isActive ? "Active" : "Deleted"} Distresses Total `}
        array={distresses}
        actions={[
          {
            _title: "Add new",
            _icon: "plus",
            _function: handleCreate,
            _condition: () => isActive,
            _shouldReset: true,
            _disabledOnSearch: true,
          },
          {
            _title: isActive ? "Edit selected" : "Restore selected",
            _icon: isActive ? "pen" : "undo-alt",
            _function: isActive ? handleUpdate : handleRestore,
            _haveSelect: true,
            _allowMultiple: false,
            _shouldReset: true,
          },
          {
            _title: "Archive",
            _icon: "trash",
            _function: handleDestroy,
            _condition: () => isActive,
            _haveSelect: true,
            _shouldReset: true,
          },
        ]}
        tableHeads={[
          {
            _text: "Name",
          },
          {
            _text: "Description",
          },
          {
            _text: "Category",
          },
          {
            _text: "Severity",
          },
          {
            _text: "Frequency",
          },
        ]}
        tableBodies={[
          {
            _key: "name",
          },
          {
            _key: "description",
          },
          {
            _key: "category",
          },
          {
            _key: "severity",
          },
          {
            _key: "frequency",
          },
        ]}
        handleSearch={handleSearch}
      />
      <Modal
        category={active}
        selected={selected}
        willCreate={willCreate}
        show={showModal}
        toggle={toggleModal}
      />
      {auth?.role?.name === "VIP" && <Bots promt="distresses" />}
    </>
  );
}
