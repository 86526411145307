import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBModalHeader,
  MDBInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBSelectInput,
  MDBSelect,
} from "mdbreact";
import { SAVE, UPDATE } from "../../../../services/redux/slices/relivers";

export default function Modal({ show, toggle, selected, willCreate }) {
  const { collections } = useSelector(({ distresses }) => distresses),
    { isLoading } = useSelector(({ relivers }) => relivers),
    { token, auth } = useSelector(({ auth }) => auth),
    [distresses, setDistresses] = useState([]),
    [form, setForm] = useState({
      user: auth._id,
      name: "",
      description: "",
      frequency: "",
      distresses: [],
      type: "",
      effectiveness: 0,
    }),
    dispatch = useDispatch();

  useEffect(() => {
    setDistresses(collections.filter(e => e.deleted === false));
  }, [collections]);

  const handleUpdate = () => {
    dispatch(
      UPDATE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      user: auth._id,
      name: "",
      description: "",
      frequency: "",
      distresses: [],
      type: "",
      effectiveness: 0,
    });
    toggle();
  };

  useEffect(() => {
    if (selected._id) {
      let updates = {
        user: auth._id,
        name: selected.name,
        description: selected.description,
        frequency: selected.frequency,
        distresses: [],
        type: selected.type,
        effectiveness: selected.effectiveness,
      };

      if (selected.distresses && selected.distresses.length > 0) {
        selected.distresses.forEach(item => {
          updates.distresses.push(item._id);
        });
      }
      setForm(updates);
    } else {
      setForm({
        user: auth._id,
        name: "",
        description: "",
        frequency: "",
        distresses: [],
        type: "",
        effectiveness: 0,
      });
    }
  }, [selected]);
  const handleCreate = () =>
    dispatch(
      SAVE({
        data: {
          ...form,
          _id: selected._id,
        },
        token,
      })
    );

  const handleSubmit = e => {
    e.preventDefault();

    if (willCreate) {
      handleCreate();
    } else {
      handleUpdate();
    }

    setForm({
      user: auth._id,
      name: "",
      description: "",
      frequency: "",
      type: "",
      distresses: [],
      effectiveness: 0,
    });
    toggle();
  };

  return (
    <MDBModal
      isOpen={show}
      toggle={toggle}
      backdrop={true}
      disableFocusTrap={false}>
      <MDBModalHeader
        toggle={toggle}
        className="light-blue darken-3 white-text">
        <MDBIcon icon="user" className="mr-2" />
        {willCreate ? "Create" : "Update"} {selected.name || "a Reliver"}
      </MDBModalHeader>
      <MDBModalBody className="mb-0">
        <form onSubmit={handleSubmit}>
          <MDBSelect
            multiple
            getValue={e => setForm({ ...form, distresses: e })}
            className="colorful-select dropdown-primary hidden-md-down"
            label="Distress">
            <MDBSelectInput />
            <MDBSelectOptions>
              {distresses.map((distress, index) => {
                let isSelected = false;
                console.log(selected);
                if (selected._id) {
                  const _distresses = selected.distresses;
                  if (
                    _distresses &&
                    _distresses.length > 0 &&
                    _distresses.some(e => e._id === distress._id)
                  ) {
                    isSelected = true;
                  }
                }

                return (
                  <MDBSelectOption
                    key={`distress-list-${index}`}
                    value={distress._id}
                    selected={isSelected}>
                    <p className="h5">
                      <b>{distress.name}</b>
                    </p>
                    <span>
                      {distress.category}-{distress.severity}/10
                    </span>
                  </MDBSelectOption>
                );
              })}
            </MDBSelectOptions>
          </MDBSelect>
          <MDBInput
            type="text"
            label="Name"
            value={form.name}
            onChange={e => setForm({ ...form, name: e.target.value })}
            required
            icon="heading"
          />
          <MDBInput
            type="text"
            label="Type (e.g Physical, Mental, Social)"
            value={form.type}
            onChange={e => setForm({ ...form, type: e.target.value })}
            required
            icon="th-large"
          />
          <MDBInput
            type="textarea"
            label="Description"
            value={form.description}
            onChange={e => setForm({ ...form, description: e.target.value })}
            required
            icon="book"
          />
          <MDBInput
            type="text"
            label="Frequency (e.g daily, weekly, monthly)"
            value={form.frequency}
            onChange={e => setForm({ ...form, frequency: e.target.value })}
            required
            icon="retweet"
          />
          <MDBInput
            type="number"
            value={form.effectiveness}
            onChange={e => setForm({ ...form, effectiveness: e.target.value })}
            required
            max={10}
            min={1}
            label="effectiveness(1-10)"
            icon="star"
          />
          <div className="text-center mb-1-half">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              color="info"
              className="mb-2"
              rounded>
              {willCreate ? "submit" : "update"}
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
