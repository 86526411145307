import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DESTROY,
  FIND,
  RESET,
  UPDATE,
} from "../../../../services/redux/slices/relivers";
import {
  BROWSE as DISTRESSES,
  RESET as DISTRESSESRESET,
} from "../../../../services/redux/slices/distresses";
import { useToasts } from "react-toast-notifications";
import Modal from "./modal";
import DataTable from "../../../../components/dataTable";
import { globalSearch } from "../../../../services/utilities";
import Swal from "sweetalert2";
import Bots from "../bots";

export default function Relivers() {
  const [relivers, setRelivers] = useState([]),
    isActive = true,
    [selected, setSelected] = useState({}),
    [showModal, setShowModal] = useState(false),
    [willCreate, setWillCreate] = useState(true),
    { token, auth } = useSelector(({ auth }) => auth),
    { collections, message, isSuccess, isLoading, total } = useSelector(
      ({ relivers }) => relivers
    ),
    { addToast } = useToasts(),
    dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(DISTRESSES(token));
      dispatch(
        FIND({
          token,
          key: {
            user: `{"$eq": "${auth._id}"}`,
          },
        })
      );
    }

    return () => {
      dispatch(RESET());
      dispatch(DISTRESSESRESET());
    };
  }, [token, auth, dispatch]);

  useEffect(() => {
    console.log(collections);

    setRelivers(collections.filter(e => e.deleted === !isActive));
  }, [isActive, collections]);

  const toggleModal = () => setShowModal(!showModal);

  const handleUpdate = selected => {
    setSelected(selected);
    if (willCreate) {
      setWillCreate(false);
    }
    setShowModal(true);
  };

  const handleCreate = () => {
    if (!willCreate) {
      setWillCreate(true);
    }
    setSelected({});
    setShowModal(true);
  };

  const handleRestore = selected =>
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to restore ${selected.length} ${
        selected.length > 1 ? "relivers" : "reliver"
      }.`,
      icon: "question",
      confirmButtonText: "Proceed",
    }).then(res => {
      if (res.isConfirmed) {
        const toRestore = selected.map(item => ({
          balance: item.balance,
          _id: item._id,
          deleted: false,
        }));

        dispatch(
          UPDATE({
            data: toRestore.length > 1 ? toRestore : toRestore[0],
            token,
          })
        );
      }
    });

  const handleDestroy = async selected => {
    const { value: reason } = await Swal.fire({
      title: "Are you sure?",
      text: `You are about to permanently delete ${selected.length} Budget(s).`,
      icon: "warning",
      confirmButtonText: "Proceed",
      cancelButtonColor: "#d33",
      showCancelButton: true,
    });

    if (reason) {
      const toDelete = selected.map(item => ({
        _id: item._id,
        balance: item.balance,
        deleted: true,
      }));

      dispatch(
        DESTROY({
          data: toDelete.length > 1 ? toDelete : toDelete[0],
          token,
        })
      );
    }
  };

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);

  const handleSearch = async (willSearch, key) => {
    if (willSearch) {
      setRelivers(globalSearch(collections, key));
    } else {
      setRelivers(collections);
    }
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        title={`${isActive ? "Active" : "Deleted"} Relivers Total `}
        array={relivers}
        actions={[
          {
            _title: "Add new",
            _icon: "plus",
            _function: handleCreate,
            _condition: () => isActive,
            _shouldReset: true,
            _disabledOnSearch: true,
          },
          {
            _title: isActive ? "Edit selected" : "Restore selected",
            _icon: isActive ? "pen" : "undo-alt",
            _function: isActive ? handleUpdate : handleRestore,
            _haveSelect: true,
            _allowMultiple: false,
            _shouldReset: true,
          },
          {
            _title: "Archive",
            _icon: "trash",
            _function: handleDestroy,
            _condition: () => isActive,
            _haveSelect: true,
            _shouldReset: true,
          },
        ]}
        tableHeads={[
          {
            _text: "Distress",
          },
          {
            _text: "Name",
          },
          {
            _text: "Description",
          },
          {
            _text: "Frequency",
          },
          {
            _text: "effectiveness",
          },
        ]}
        tableBodies={[
          {
            _key: "distresses",
            _format: models => {
              console.log(models);
              if (models && models.length > 0) {
                return models.map((data, i) => {
                  return (
                    <div key={`distress-${i}`}>
                      <p className="h5">{data.name}</p>
                      <span>
                        {data.category}-{data.effectiveness}/10
                      </span>
                    </div>
                  );
                });
              }
            },
          },
          {
            _key: "name",
          },
          {
            _key: "description",
          },
          {
            _key: "frequency",
          },
          {
            _key: "effectiveness",
          },
        ]}
        handleSearch={handleSearch}
      />
      <Modal
        selected={selected}
        willCreate={willCreate}
        show={showModal}
        toggle={toggleModal}
      />
      {auth?.role?.name === "VIP" && <Bots promt="relivers" />}
    </>
  );
}
