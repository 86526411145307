import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import users from "./slices/users";
import roles from "./slices/roles";
import statistics from "./slices/statistics";
import chats from "./slices/chats";
import hobbies from "./slices/hobbies";
import relivers from "./slices/relivers";
import distresses from "./slices/distresses";

const store = configureStore({
  reducer: {
    auth,
    users,
    roles,
    statistics,
    hobbies,
    chats,
    distresses,
    relivers,
  },
});

export default store;
