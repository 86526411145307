import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import { SAVE, UPDATE } from "../../../../services/redux/slices/hobbies";

export default function Modal({ show, toggle, selected, willCreate }) {
  const { isLoading } = useSelector(({ hobbies }) => hobbies),
    { token, auth } = useSelector(({ auth }) => auth),
    [form, setForm] = useState({
      user: auth._id,
      name: "",
      description: "",
      frequency: "",
      benefits: "",
      duration: 0,
    }),
    dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(
      UPDATE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      user: auth._id,
      name: "",
      description: "",
      frequency: "",
      benefits: "",
      duration: 0,
    });
    toggle();
  };

  useEffect(() => {
    if (selected._id) {
      let updates = {
        user: auth._id,
        name: selected.name,
        description: selected.description,
        frequency: selected.frequency,
        benefits: selected.benefits,
        duration: selected.duration,
      };
      setForm(updates);
    } else {
      setForm({
        user: auth._id,
        name: "",
        description: "",
        frequency: "",
        benefits: "",
        duration: 0,
      });
    }
  }, [selected]);
  const handleCreate = () =>
    dispatch(
      SAVE({
        data: {
          ...form,
          _id: selected._id,
        },
        token,
      })
    );

  const handleSubmit = e => {
    e.preventDefault();

    if (willCreate) {
      handleCreate();
    } else {
      handleUpdate();
    }

    setForm({
      user: auth._id,
      name: "",
      description: "",
      frequency: "",
      benefits: "",
      duration: 0,
    });
    toggle();
  };

  return (
    <MDBModal
      isOpen={show}
      toggle={toggle}
      backdrop={true}
      disableFocusTrap={false}>
      <MDBModalHeader
        toggle={toggle}
        className="light-blue darken-3 white-text">
        <MDBIcon icon="user" className="mr-2" />
        {willCreate ? "Create" : "Update"} {selected.name || "a Hobby"}
      </MDBModalHeader>
      <MDBModalBody className="mb-0">
        <form onSubmit={handleSubmit}>
          <MDBInput
            type="text"
            label="Name"
            value={form.name}
            onChange={e => setForm({ ...form, name: e.target.value })}
            required
            icon="heading"
          />
          <MDBInput
            type="textarea"
            label="Description"
            value={form.description}
            onChange={e => setForm({ ...form, description: e.target.value })}
            required
            icon="book"
          />
          <MDBInput
            type="text"
            label="Frequency (e.g daily, weekly, monthly)"
            value={form.frequency}
            onChange={e => setForm({ ...form, frequency: e.target.value })}
            required
            icon="retweet"
          />
          <MDBInput
            type="text"
            label="benefits"
            value={form.benefits}
            onChange={e => setForm({ ...form, benefits: e.target.value })}
            required
            icon="user-check"
          />
          <MDBInput
            type="number"
            value={form.duration}
            onChange={e => setForm({ ...form, duration: e.target.value })}
            required
            label="Duration (in minutes max)"
            icon="clock"
          />
          <div className="text-center mb-1-half">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              color="info"
              className="mb-2"
              rounded>
              {willCreate ? "submit" : "update"}
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
