const locations = location => {
  switch (location) {
    case "/dashboard":
      return "Dashboard";

    case "/roles":
      return "Listed Roles";

    case "/users":
      return "Manage Users";

    case "/hobbies":
      return "Your Hobbies";

    case "/distresses":
      return "Your Distresses";

    case "/relivers":
      return "Your Relivers";

    case "/chat":
      return "Your Kaagapp-AI";

    case "/profile":
      return "Your Profile";

    case "/profile/update":
      return "Update Profile";

    default:
      return "Looks like you're lost.";
  }
};

export default locations;
